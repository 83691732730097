<template>
  <div class="flex flex-col flex-1 overflow-y-auto">
    <component
      :is="layoutComponent"
      :project="project"
      :assets="assets"
      :active-folder="activeFolder"
      :focused-asset="focusedAsset"
      :multiselected-assets="multiselectedAssets"
      :can-preview="canPreview"
      :can-select="canSelect"
      :can-replace="canReplace"
      :can-delete="canDelete"
      :can-rename="canRename"
      :dragger="dragger"
      :selected-asset="selectedAsset"
      :sort-order="sortOrder"
      :display-mode="displayMode"
      :restricted-action-types="restrictedActionTypes"
      :action-mode="actionMode"
      @multiselect-asset="$emit('multiselect-asset', $event)"
      @multideselect-asset="$emit('multideselect-asset', $event)"
      @delete-asset="$emit('delete-asset', $event)"
      @preview-asset="$emit('preview-asset', $event)"
      @rename-asset="$emit('rename-asset', $event)"
      @replace-asset="$emit('replace-asset', $event)"
      @deselect-asset="$emit('deselect-asset', $event)"
      @drag-asset="$emit('drag-asset', $event)"
      @drag-asset-focus="$emit('drag-asset-focus', $event)"
      @drop-asset="$emit('drop-asset', $event)"
      @select-asset="$emit('select-asset', $event)"
      @view-asset="$emit('view-asset', $event)"
      @move-asset="$emit('move-asset', $event)"
    />
  </div>
  <!-- /.collection -->
</template>

<script>
const ListLayout = () => import('./assets-explorer-collection/assets-explorer-collection-list-layout.vue')
const TreeLayout = () => import('./assets-explorer-collection/assets-explorer-collection-tree-layout.vue')
const GridLayout = () => import('./assets-explorer-collection/assets-explorer-collection-grid-layout.vue')
const EmbeddedLayout = () => import('./assets-explorer-collection/assets-explorer-collection-embedded-layout.vue')

export default {
  name: 'AssetsExplorerCollection',

  components: {
    'tree-layout': TreeLayout,
    'list-layout': ListLayout,
    'grid-layout': GridLayout,
    'embedded-layout': EmbeddedLayout
  },

  props: {

    project: {
      required: true,
      type: Object
    },
    assets: {
      required: true,
      type: Array
    },
    activeFolder: {
      required: true,
      type: Object
    },
    multiselectedAssets: {
      default: [],
      type: Array
    },
    focusedAsset: {
      default: null,
      type: Object
    },
    displayMode: {
      default: 'full',
      type: String
    },
    canPreview: {
      required: true,
      type: Boolean
    },
    canSelect: {
      required: true,
      type: Boolean
    },
    canReplace: {
      required: true,
      type: Boolean
    },
    canDelete: {
      required: true,
      type: Boolean
    },
    canRename: {
      required: true,
      type: Boolean
    },
    dragger: {
      default: null,
      type: Object
    },
    layout: {
      required: true,
      type: String
    },
    sortOrder: {
      default: null,
      type: String
    },
    selectedAsset: {
      default: null,
      type: Object
    },
    restrictedActionTypes: {
      type: Array,
      default: () => []
    },
    actionMode: {
      type: String,
      required: true
    }
  },

  computed: {

    layoutComponent: function () {
      if (this.displayMode === 'mini') return 'embedded-layout'
      if (this.displayMode === 'overview') return 'grid-layout'
      if (this.layout === 'card') return 'grid-layout'
      if (this.layout === 'tree') return 'tree-layout'
      return 'list-layout'
    }

  },

  methods: {
    isSelectedAsset: function (asset) {
      return this.selectedAsset && asset.id === this.selectedAsset.id
    }
  }
}
</script>
